<template>
  <div class="manage-agency-users-add animatedBox">
    <div class="container fluid">
      <fd-form @submit.prevent="submitForm" @error="validateFail">
        <div class="card p-3">
          <h3 class="page-title mb-4">Add Agency User</h3>
          <!-- Agency Details -->
          <fd-form-section title="Agency Details">
            <fd-select
              v-model="newAgencyUser.agencyId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select Agency"
              :options="agencyOptions"
              :validators="[validator.required]"
              @input="agencyChanged"
            >
            </fd-select>
            <fd-select
              v-model="newAgencyUser.branchId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select Branch"
              :options="branchOptions"
              :selectText="
                $isStringEmpty(newAgencyUser.agencyId)
                  ? 'Select an agency first'
                  : 'Select a branch'
              "
              :disabled="$isStringEmpty(newAgencyUser.agencyId)"
              :validators="[validator.required]"
            >
            </fd-select>
            <fd-select
              v-model="newAgencyUser.roleId"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select Role"
              :options="roleOptions"
              :selectText="
                $isStringEmpty(newAgencyUser.agencyId)
                  ? 'Select an agency first'
                  : 'Select a role'
              "
              :disabled="$isStringEmpty(newAgencyUser.agencyId)"
              :validators="[validator.required]"
            >
            </fd-select>
            <fd-input
              v-model="newAgencyUser.renLicense"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="REN/E/PEA License"
              name="renLicense"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
          </fd-form-section>
          <fd-form-section title="Account Credential">
            <fd-input
              v-model="newAgencyUser.email"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Email"
              name="email"
              type="email"
              :validators="[validator.required, validator.email]"
            >
            </fd-input>
            <fd-input
              v-model="newAgencyUser.password"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Password"
              name="password"
              :type="showPassword ? 'text' : 'password'"
              :validators="[validator.required, validator.password]"
            >
              <template #append-icon>
                <span
                  class="cursor-pointer"
                  @click="showPassword = !showPassword"
                >
                  <i
                    class="fa"
                    :class="{
                      'fa-eye': !showPassword,
                      'fa-eye-slash': showPassword
                    }"
                  ></i>
                </span>
              </template>
            </fd-input>
          </fd-form-section>
          <!-- Personal Information -->
          <div class="col-12 px-1 mb-2">
            <label class="label">Avatar</label>
            <image-uploader
              v-model="newAgencyUser.avatar"
              :multiple="false"
              @error="
                (error, imageName) => {
                  $reportError(error, 'Upload avatar (Add A.User)');
                }
              "
            >
            </image-uploader>
          </div>
          <fd-form-section title="Personal Information">
            <fd-input
              v-model="newAgencyUser.name"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Name"
              name="name"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <fd-input
              v-model="newAgencyUser.actualName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Actual Name"
              name="actualName"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>

            <fd-select
              v-model="newAgencyUser.gender"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Select a gender"
              :options="genderOptions"
              :validators="[validator.required]"
              @update="(phone) => (telNo = phone)"
            >
            </fd-select>
            <fd-date-picker
              v-model="newAgencyUser.birthdate"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Birth Date"
              name="birthdate"
            >
            </fd-date-picker>

            <tel-input
              v-model="phoneInput"
              class="col-12 px-1 mb-2 d-block"
              label="Contact No."
              required
            >
            </tel-input>
            <fd-textarea
              v-model="newAgencyUser.introduction"
              class="col-12 px-1 mb-2"
              label="Introduction"
              name="introduction"
            >
            </fd-textarea>
            <fd-textarea
              v-model="newAgencyUser.experience"
              class="col-12 px-1 mb-2"
              label="Experience"
              name="experience"
            >
            </fd-textarea>
          </fd-form-section>
          <!-- Bank Details -->
          <fd-form-section title="Bank Details">
            <fd-input
              v-model="newAgencyUser.bank"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Bank Name"
              name="bank"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="newAgencyUser.bankAccountNumber"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Bank Account No."
              name="bankAccountNumber"
              type="text"
            >
            </fd-input>
            <fd-input
              v-model="newAgencyUser.bankAccountFullName"
              class="col-12 sm-col-6 md-col-3 px-1 mb-2"
              label="Account Full Name"
              name="bankAccountFullName"
              type="text"
              :validators="[(val) => validator.min(val, 3)]"
            >
            </fd-input>
          </fd-form-section>
          <!-- Address Details -->
          <fd-form-section title="Address Details">
            <fd-input
              v-model="newAgencyUser.address"
              class="col-12 sm-col-6 px-1 mb-2"
              label="Address"
              name="address"
              type="text"
              :validators="[validator.required]"
            >
            </fd-input>
            <addresses
              :country.sync="newAgencyUser.country"
              :state.sync="newAgencyUser.state"
              :city.sync="newAgencyUser.city"
              :area.sync="newAgencyUser.area"
              class="row col-12"
              @change="
                (data) => {
                  newAgencyUser.countryId = data.country.id;
                  newAgencyUser.stateId = data.state.id;
                  newAgencyUser.cityId = data.city.id;
                  newAgencyUser.areaId = data.area.id;
                  newAgencyUser.buildingId = data.building.id;
                }
              "
            ></addresses>
          </fd-form-section>
          <div class="text-right p-2">
            <button class="btn main">Create</button>
          </div>
        </div>
      </fd-form>
    </div>
  </div>
</template>

<script>
import {
  required,
  email,
  phone,
  password,
  minLength
} from "@/components/GlobalComponents/FormComponents/Validator/rules";

import genderEnum from "@/enums/gender";

import Addresses from "@/components/GlobalComponents/Address";
import { agencyUser as AgencyUserAPI } from "@/api";
import { AgencyUserModel } from "@/models";

export default {
  components: {
    Addresses,
    TelInput: () => import("@/modules/User/components/TelInput"),
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader")
  },
  mixins: [],
  props: {},
  data: function () {
    return {
      agencyOptions: [],
      branchOptions: [],
      roleOptions: [],

      genderOptions: this.$mapJsonToArray(genderEnum, (e) => {
        return {
          id: e,
          name: e
        };
      }),

      showPassword: false,

      phoneInput: "",
      telNo: {},

      newAgencyUser: {
        agencyId: "",
        branchId: "",
        roleId: "",
        renLicense: "",

        address: "",

        country: "",
        state: "",
        city: "",
        area: "",
        countryId: "",
        stateId: "",
        cityId: "",
        areaId: "",

        name: "",
        email: "",
        password: "",
        avatar: [],
        mobileISOCode: "",
        mobileCountryCode: "",
        mobileNumber: "",
        actualName: "",
        birthdate: "",
        introduction: "",
        experience: "",
        gender: "",

        bankAccountNumber: "",
        bankAccountFullName: "",
        bank: ""
      },

      validator: {
        required: required,
        email: email,
        phone: phone,
        password: password,
        min: minLength
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initData();
  },
  methods: {
    async initData() {
      await this.getAgencies();
    },
    async agencyChanged() {
      try {
        this.$store.commit("setIsLoading", true);
        // Clear branch and role selection
        this.newAgencyUser.branchId = "";
        this.newAgencyUser.roleId = "";
        // Get branch & role options
        if (this.newAgencyUser.agencyId) {
          await Promise.all([this.getBranches(), this.getRoles()]);
        }
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        throw error;
      }
    },
    validateFail() {
      this.$notify({
        group: "alert",
        type: "error",
        title: "Invalid Input",
        text: "Please check if the inputs are valid."
      });
    },
    // ============================== API Related ==============================
    async getAgencies() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await this.$store.dispatch("manageAgency/getAllAgencies", {
          limit: 200
        });
        this.agencyOptions = this._.cloneDeep(data.data);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch agencies. Please try again later."
        });
      }
    },
    async getBranches() {
      let data = await this.$store.dispatch("manageBranch/getAllBranches", {
        "agency:id": this.newAgencyUser.agencyId,
        limit: 200
      });
      this.branchOptions = this._.cloneDeep(data.data);
    },
    async getRoles() {
      let data = await this.$store.dispatch(
        "manageAgency/getAgencyRoles",
        this.newAgencyUser.agencyId
      );
      this.roleOptions = this._.cloneDeep(data.data);
    },
    async submitForm() {
      this.$store.commit("setIsLoading", true);
      try {
        await AgencyUserAPI.createAgencyUser(
          AgencyUserModel.postAgencyUserPayload(this.newAgencyUser)
        );
        this.$store.commit("setIsLoading", false);

        this.$notify({
          group: "alert",
          type: "success",
          title: "Success",
          text: "Agency user is created successfully."
        });
        this.$router.push({ name: "ManageAgencyUsers" });
        return;
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "An unexpected error occured. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss">
.manage-agency-users-add {
  form {
    @extend %formDesign;
  }
  .page-title {
    font-size: 24px;
  }
}
</style>
